<template>
  <div className="hello">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
import http from '../api/index'

export default {
  name: 'cashBoard',
  data() {
    return {
      msg: '',
      wechat: false,
      ali: false,
      appId: 'wxdb243c2208911f1a', // 微信公众号appId
      wechatCode: '',
      chargeOrderId: '',
      scene: null
    }
  },
  created() {
    console.log(navigator.userAgent.toLowerCase())
    if (this.isAlipay()) {
      this.ali = true
    } else if (this.isWechat()) {
      this.wechat = true
    }

    if (this.wechat) {
      // 获取微信openId
      console.log('wechat')
      this.filterUrl()
      this.getExpendInfo()
    } else {
      if (this.ali) {
        console.log('ali')
      } else {
        console.log('browser')
      }
      this.chargeOrderId = this.getUrlParam('chargeOrderId')
      this.scene = this.getUrlParam('scene')
      this.getExpendInfo()
    }
  },
  methods: {
    isAlipay() {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.match(RegExp(/alipay/))) {
        return true
      } else {
        return false
      }
    },
    isWechat() {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.match(RegExp(/micromessenger/))) {
        return true
      } else {
        return false
      }
    },
    getUrlParam(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let url = window.location.href.split('#')[0]
      let search = url.split('?')[1]
      if (search) {
        const r = search.substr(0).match(reg)
        if (r !== null) {
          return unescape(r[2])
        }
        return null
      } else {
        return null
      }
    },
    filterUrl() {
      const wechatCode = this.getUrlParam('code')
      console.log('wechatCode:' + wechatCode)
      if (wechatCode) {
        this.wechatCode = wechatCode
        this.chargeOrderId = this.getUrlParam('chargeOrderId')
        this.scene = this.getUrlParam('scene')
        this.getExpendInfo()
      } else {
        // 首次打开跳转
        const location = window.location.href
        const redirectUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${encodeURIComponent(
          location
        )}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`
        console.log(redirectUrl)
        window.location.href = redirectUrl
      }
    },
    getExpendInfo() {
      http
        .getExpendInfo({
          mpAppId: this.appId,
          code: this.wechatCode,
          chargeOrderId: this.chargeOrderId,
          scene: this.scene,
          payType: this.isWechat() ? 1 : this.isAlipay() ? 2 : 0
        })
        .then(res => {
          // 拿到了expendInfo
          console.log(res.data)
          if (this.wechat) {
            // 调起微信支付
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', JSON.parse(res.data.pay_info),
                function (res) {
                  if (res.err_msg == 'get_brand_wcpay_request:ok') {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  }
                }
            )
          } else {
            // 调起支付宝支付
            console.log(res.data.qrcode_url)
            window.location.href = res.data.qrcode_url
            // window.location.href='alipays://platformapi/startapp?saId=10000007&qrcode=' + res.data.payInfo
          }
      })
    }
  }
}
</script>

<style scoped lang="scss">
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
