/*
 * @Descripttion:
 * @Author: shibin
 * @LastEditors: shibin
 * @LastEditTime: 2022-07-23 12:58:03
 */
import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import cashBoard from '@/views/cashBoard'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'cash',
      component: cashBoard
    }
  ]
})
