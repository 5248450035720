/*
 * @Descripttion:公共请求
 * @Author: shibin
 * @LastEditors: shibin
 * @LastEditTime: 2022-07-23 13:16:41
 */
import axios from 'axios'

export const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

//响应拦截器
request.interceptors.response.use(
  res => {
    switch (res.data.code) {
      case 200:
        return res.data
      case 401:
        return Promise.reject(res)
      default:
        return Promise.reject(res)
    }
  },
  err => {
    const { response } = err
    switch (response.status) {
      case 400:
        break
      case 401:
        break
      default:
        break
    }
    return Promise.reject(response)
  }
)
//请求拦截器
request.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default request
