/*
 * @Descripttion:
 * @Author: shibin
 * @LastEditors: shibin
 * @LastEditTime: 2022-07-23 13:18:22
 */
import { request } from '@/request'

const http = {
  login(params) {
    let url = '/order/detail'
    return request({ url, method: 'get', params })
  },
  postUrl(data) {
    let url = '/order/detail'
    return request({ url, method: 'post', data })
  },
  getExpendInfo(data) {
    let url = '/v1/auth/getExpendInfo'
    return request({ url, method: 'post', data })
  }
}

export default http
